export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21')
];

export const server_loads = [0,6,2];

export const dictionary = {
		"/": [~7],
		"/auth/(unauthenticated)/forgot-password": [~15,[5,6]],
		"/auth/(unauthenticated)/forgot-password/sent": [16,[5,6]],
		"/auth/(unauthenticated)/login": [~17,[5,6]],
		"/auth/(unauthenticated)/register": [~18,[5,6]],
		"/auth/(unauthenticated)/reset-password": [~19,[5,6]],
		"/auth/(unauthenticated)/reset-password/success": [20,[5,6]],
		"/auth/verify": [~21,[5]],
		"/(authenticated)/connect": [~8,[2]],
		"/(authenticated)/connect/success": [~9,[2]],
		"/(authenticated)/dashboard": [~10,[2,3]],
		"/(authenticated)/dashboard/logout": [~11,[2,3]],
		"/(authenticated)/dashboard/top-up": [~12,[2,3]],
		"/(authenticated)/dashboard/top-up/success": [~13,[4]],
		"/(authenticated)/dashboard/usage": [~14,[2,3]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';